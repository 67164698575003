import { doDataVaultRequest } from '@/utils/request';

import * as urls from '../apiUrl';

const callbackContactUsApis = async (data: any) => {
  return doDataVaultRequest(`${urls.CONTACT_POST_API}`, 'post', data);
};

const callbackValetServiceApis = async (data: any) => {
  return doDataVaultRequest(`${urls.BOOK_POST_API}`, 'post', data);
};

const callbackCallMeApis = async (
  data: any,
  formType: any,
  storeType: any = 'ss'
) => {
  return doDataVaultRequest(
    `${urls.getcallMebackUrl(formType, storeType)}`,
    'post',
    data
  );
};

const callbackSmallBusinessApis = async (data: any) => {
  return doDataVaultRequest(`${urls.SMALL_BUSINESS_POST_API}`, 'post', data);
};

const callbackTeamCanadaApis = async (data: any) => {
  return doDataVaultRequest(`${urls.TEAM_CANADA_POST_API}`, 'post', data);
};

const referralCallApis = async (data: any) => {
  return doDataVaultRequest(urls.REFERRAL_POST_API, 'post', data);
};

export {
  callbackCallMeApis,
  callbackContactUsApis,
  callbackSmallBusinessApis,
  callbackTeamCanadaApis,
  callbackValetServiceApis,
  referralCallApis,
};
