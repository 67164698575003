import { FormattedMessage } from 'react-intl';

const RecaptchaBranding = () => {
  return (
    <>
      <FormattedMessage id="form-recaptcha-protected-by"></FormattedMessage>
      <a
        href="https://policies.google.com/privacy"
        className="link text-decoration-none"
        target="_blank"
        rel="noreferrer"
      >
        {' '}
        <FormattedMessage id="form-recaptcha-privacy-policy"></FormattedMessage>
      </a>{' '}
      <FormattedMessage id="form-recaptcha-and"></FormattedMessage>{' '}
      <a
        href="https://policies.google.com/terms"
        className="link"
        target="_blank"
        rel="noreferrer"
      >
        <FormattedMessage id="form-recaptcha-terms"></FormattedMessage>
      </a>{' '}
      <FormattedMessage id="form-recaptcha-apply"></FormattedMessage>
    </>
  );
};

export default RecaptchaBranding;
