import 'react-datepicker/dist/react-datepicker.css';

import moment from 'moment';
import { useRouter } from 'next/router';
import type { Ref } from 'react';
import { forwardRef, useEffect, useRef, useState } from 'react';
import DatePicker from 'react-datepicker';
import type { SubmitHandler } from 'react-hook-form';
import { Controller, useForm } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';

import { toSentenceCase } from '@/helper/wordReplace';
import {
  callbackCallMeApis,
  callbackValetServiceApis,
} from '@/services/callback';
import {
  makeCallBackRequest,
  makeValetServiceRequest,
} from '@/services/callback/make-request';
import {
  FORM_ERRORS,
  GOOGLE_SITE_LINK_KEY,
  PHONE_NUMBERS,
  PLACEHOLDERS,
  RECAPTCH_URL,
} from '@/utils/constants';

import { allowNumbers } from '../utility';
import RecaptchaBranding from './recaptcha-branding';
import ThankyouContent from './thankyou-content';

type Inputs = {
  fullName: string;
  email: string;
  phone: number;
  description: string;
  date: Date;
};
const CallbackModal = (props: any) => {
  const {
    facilityId,
    unitRate,
    unitDimensions,
    ero,
    unitTypeId,
    PromotionSLName,
    promotionDescription,
    consessionId,
    unitWidth,
    unitLength,
    formType,
    storeType = 'ss',
  } = props;

  const [onChangeValue, setOnChangeValue] = useState('');
  const path =
    typeof window !== 'undefined' &&
    window &&
    window.location &&
    window?.location?.href;
  const [isModalClose, setIsModalClose] = useState('');
  const recaptchaRef = useRef(null);
  const { locale }: any = useRouter();
  let fillTimer: any;
  const [loading, setLoading] = useState(false);
  const [responseType, setResponseType] = useState('');
  const [phoneNumberRC, setphoneNumberRC] = useState(
    PHONE_NUMBERS.SELF_STORAGE
  );

  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm<Inputs>();
  const router = useRouter();
  const [selectedDate, setSelectedDate] = useState<any>();

  const networkError = (error: any) => {
    setResponseType('error');
    console.log('Error', error);
  };

  const onSubmit: SubmitHandler<Inputs> = async (data: any) => {
    if (data) {
      // eslint-disable-next-line no-param-reassign
      data.date = selectedDate
        ? moment(selectedDate).format('YYYY-MM-DD')
        : moment(new Date()).format('YYYY-MM-DD');

      const { token, action } = data;
      if (storeType === 'valet') {
        const request = makeValetServiceRequest(
          data,
          token,
          path,
          unitDimensions,
          unitRate,
          locale
        );
        try {
          const response = await callbackValetServiceApis(request);

          if (response.status !== 200) {
            throw new Error(`API Error: ${response.data?.message}`);
          } else {
            setResponseType('success');
            window.dataLayer.push({ event: 'CallBack' });
          }
        } catch (error) {
          networkError(error);
        } finally {
          setLoading(false);
        }
      } else {
        const request = makeCallBackRequest(
          data,
          token,
          path,
          facilityId,
          unitRate,
          unitDimensions,
          ero,
          locale,
          unitTypeId,
          PromotionSLName,
          promotionDescription,
          consessionId,
          unitWidth,
          unitLength,
          storeType,
          action
        );

        try {
          const response = await callbackCallMeApis(
            request,
            formType,
            storeType
          );

          if (response.status !== 200) {
            throw new Error(`API Error: ${response.data?.message}`);
          } else {
            const redirectUrl = response?.data?.result?.redirectURL || null;
            if (redirectUrl && redirectUrl.length) {
              clearTimeout(fillTimer);
              setResponseType('redirect');
              router.push(redirectUrl);
            } else {
              setResponseType('success');
              setLoading(false);
            }
            if (formType === 'reserve') {
              window.dataLayer.push({ event: 'Reserve' });
              // window.gtag('event', `Reserve - ${promotionDescription}`);
            } else {
              window.dataLayer.push({ event: 'CallBack' });
              // window.gtag('event', `CallBack - ${promotionDescription}`);
            }
          }
        } catch (error) {
          networkError(error);
          setLoading(false);
        }
      }
    }
  };

  const hideModal = () => {
    setIsModalClose('modal');
  };

  const handleClick = async (data: any) => {
    const action = formType !== '' ? formType : 'request';
    window.grecaptcha.ready(() => {
      window.grecaptcha
        .execute(GOOGLE_SITE_LINK_KEY, {
          action,
        })
        .then((token: any) => {
          setLoading(true);
          onSubmit({ ...data, token, action });
          fillTimer = window.setTimeout(hideModal, 2000);
        });
    });
  };

  const loadRecaptchaScript = () => {
    const script = document.createElement('script');
    script.src = RECAPTCH_URL;
    script.async = true;
    document.body.appendChild(script);
    recaptchaRef.current = true;
  };

  // Phone Number validation
  const handleChange = (event: any) => {
    const { value } = event.target;
    if (value.length <= 10 && (allowNumbers(value) || value === '')) {
      setOnChangeValue(value);
      window.phone = value;
    }
    if (!recaptchaRef.current) {
      loadRecaptchaScript();
    }
  };

  const handleEmailChange = (event: any) => {
    const { value } = event.target;
    if (value?.length > 0) {
      window.email = value;
    }
  };

  // eslint-disable-next-line react/display-name
  const CustomInput = forwardRef(
    // eslint-disable-next-line @typescript-eslint/no-shadow
    (props: any, ref: Ref<HTMLInputElement>) => {
      return <input {...props} forwardRef={ref} readOnly />;
    }
  );
  useEffect(() => {
    switch (storeType) {
      case 'ss':
        return setphoneNumberRC(PHONE_NUMBERS.SELF_STORAGE);
      case 'valet':
        return setphoneNumberRC(PHONE_NUMBERS.VALET_SERVICE);
      case 'ps':
        return setphoneNumberRC(PHONE_NUMBERS.PORTABLE_STORAGE);
      default:
        return setphoneNumberRC(PHONE_NUMBERS.SELF_STORAGE);
    }
  }, []);

  useEffect(() => {
    setResponseType('');
    setIsModalClose('');
    setLoading(false);
    reset({
      fullName: '',
      email: '',
      description: '',
      phone: null,
    });
  }, [props]);

  const isFormEntryScreen =
    !loading && (responseType === '' || responseType === 'error');

  const ModalHeadline = () => {
    const modalTitle = () => {
      if (promotionDescription) {
        return toSentenceCase(promotionDescription);
      }
      if (formType === 'reserve') {
        return <FormattedMessage id="form.reserveHeader"></FormattedMessage>;
      }
      return <FormattedMessage id="form.callbackHeader"></FormattedMessage>;
    };

    return (
      <div className="w-100 text-center">
        <h2 className="modal-title pt-2 mb-md-2" id="modalLabel">
          {modalTitle()}
        </h2>
        <p className="pt-xs-2 m-0 small-text">
          <FormattedMessage id="form.message"></FormattedMessage>
        </p>
      </div>
    );
  };

  return (
    <div className="sv-modal-popups">
      <div
        className="modal fade"
        id="requestCallbackModal"
        aria-labelledby="modalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered ">
          <div className="modal-content">
            <div className="modal-header px-0 pb-2 ">
              {isFormEntryScreen && <ModalHeadline />}
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            {isFormEntryScreen && (
              <>
                <div className="modal-body">
                  <form className="sv-modal-form">
                    <div className="form-group">
                      <input
                        placeholder={
                          locale === 'en'
                            ? PLACEHOLDERS.EN.FULLNAME
                            : PLACEHOLDERS.FR.FULLNAME
                        }
                        type="text"
                        className={`form-control ${
                          errors?.fullName && ' error'
                        }`}
                        {...register('fullName', {
                          required:
                            locale === 'en'
                              ? FORM_ERRORS.EN.FULLNAME
                              : FORM_ERRORS.FR.FULLNAME,
                          pattern: {
                            value: /^[a-zA-Z\s]{0,50}$/,
                            message:
                              locale === 'en'
                                ? FORM_ERRORS.EN.ENTER_CHARS
                                : FORM_ERRORS.FR.FULLNAME,
                          },
                        })}
                      />
                      {errors.fullName?.message && (
                        <div className="error-msg text-end">
                          {errors.fullName?.message}
                        </div>
                      )}
                    </div>

                    <div className="form-group">
                      <input
                        placeholder={
                          locale === 'en'
                            ? PLACEHOLDERS.EN.EMAIL
                            : PLACEHOLDERS.FR.EMAIL
                        }
                        type="email"
                        id="email"
                        className={`form-control ${errors?.email && ' error'}`}
                        {...register('email', {
                          onChange: (e) => handleEmailChange(e),
                          required:
                            locale === 'en'
                              ? FORM_ERRORS.EN.EMAIL
                              : FORM_ERRORS.FR.EMAIL,
                          pattern: {
                            value: /^\S+@\S+$/i,
                            message:
                              locale === 'en'
                                ? FORM_ERRORS.EN.ENTER_VALID
                                : FORM_ERRORS.FR.EMAIL,
                          },
                        })}
                      />
                      {errors.email?.message && (
                        <div className="error-msg text-end">
                          {errors.email?.message}
                        </div>
                      )}
                    </div>

                    <div className="form-group">
                      <input
                        placeholder={
                          locale === 'en'
                            ? PLACEHOLDERS.EN.PHONE
                            : PLACEHOLDERS.FR.PHONE
                        }
                        type="tel"
                        id="phone"
                        className={`form-control ${errors?.phone && ' error'}`}
                        value={onChangeValue}
                        {...register('phone', {
                          onChange: (e) => handleChange(e),
                          required:
                            locale === 'en'
                              ? FORM_ERRORS.EN.PHONE
                              : FORM_ERRORS.FR.PHONE,
                          pattern: {
                            value: /^(?![01])[0-9]{10}$/,
                            message:
                              locale === 'en'
                                ? FORM_ERRORS.EN.ENTER_PHONE
                                : FORM_ERRORS.FR.PHONE,
                          },
                        })}
                      />
                      {errors.phone?.message && (
                        <div className="error-msg text-end">
                          {errors.phone?.message}
                        </div>
                      )}
                    </div>

                    {formType === 'reserve' && (
                      <div className="form-group">
                        <Controller
                          control={control}
                          name="date"
                          rules={{ required: true }}
                          render={({ field }) => (
                            <DatePicker
                              className={`form-control ${
                                errors?.date ? ' error' : ' movein-date'
                              }`}
                              value={selectedDate}
                              {...register('date')}
                              onChange={(date) => {
                                field.onChange(date);
                                setSelectedDate(date);
                              }}
                              selected={field.value}
                              minDate={moment().toDate()}
                              dateFormat="yyyy-MM-dd"
                              showPopperArrow={false}
                              placeholderText={
                                locale === 'en'
                                  ? PLACEHOLDERS.EN.MOVEIN_DATE
                                  : PLACEHOLDERS.FR.MOVEIN_DATE
                              }
                              fixedHeight
                              calendarClassName="custom-datepicker"
                              withPortal={true}
                              onKeyDown={(e) => {
                                e.preventDefault();
                              }}
                              customInput={<CustomInput />}
                            />
                          )}
                        />
                        {errors.date && (
                          <div className="error-msg text-end">
                            <FormattedMessage id="form.moveInDateRequired"></FormattedMessage>
                          </div>
                        )}
                      </div>
                    )}
                    <div className="form-group">
                      <input
                        placeholder={
                          locale === 'en'
                            ? PLACEHOLDERS.EN.MESSAGE
                            : PLACEHOLDERS.FR.MESSAGE
                        }
                        type="textarea"
                        className="form-control"
                        {...register('description', {})}
                      />
                    </div>
                  </form>
                </div>
                <div className="modal-footer">
                  <button
                    className="btn dp-primary-btn m-0 w-100 submit-btn"
                    type="submit"
                    onClick={handleSubmit(handleClick)}
                    data-bs-dismiss={isModalClose}
                  >
                    <FormattedMessage
                      id={`${
                        formType === 'reserve' ? 'Reserve' : 'callMeBack'
                      }`}
                    ></FormattedMessage>
                  </button>

                  <div className="mt-3 mt-md-4 mb-md-3 small-text">
                    <FormattedMessage
                      id="form.modalNote"
                      values={{
                        phone_number: <b>{phoneNumberRC}</b>,
                      }}
                    />
                  </div>
                  <div className="recaptcha-note">
                    <RecaptchaBranding />
                  </div>
                </div>
              </>
            )}
            {(loading || responseType === 'redirect') && (
              <div className="modal-body">
                <div className="d-flex justify-content-center my-4">
                  <div className="spinner-border text-primary" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div>
                </div>
                <div className="m-4">
                  <FormattedMessage id="ero-loading-message"></FormattedMessage>
                </div>
              </div>
            )}
            {!loading && responseType === 'success' && (
              <div className="modal-body">
                <ThankyouContent phoneNumber={phoneNumberRC} />
              </div>
            )}
            {!loading && responseType === 'error' && (
              <div className="error-msg">
                <b>
                  <FormattedMessage id="form.thereWasAnErrorSubmittingYourRequest"></FormattedMessage>
                </b>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CallbackModal;
